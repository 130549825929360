import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import { BuyerPreferences } from '../../_components/types';

export const toAnalyticsProps = (s: BuyerPreferences) => {
  // do not include FormState props
  return {
    brands: s.brands.map((b) => b.label),
    composite_categories: s.compositeCategories,
    countries: s.countries.map((c) => c.label),
    platforms: s.platforms.map((p) => p.label),
    price_per_unit_in_cents_max: s.pricePerUnitInCentsMax,
    price_per_unit_in_cents_min: s.pricePerUnitInCentsMin,
  };
};

export const sendPreferencesUpdatedEvent = async (
  oldPrefs: BuyerPreferences,
  newPrefs: BuyerPreferences,
) => {
  analytics.track(AnalyticsEventNames.BUYER_PREFERENCES_SUBMITTED, {
    old_preferences: toAnalyticsProps(oldPrefs),
    new_preferences: toAnalyticsProps(newPrefs),
  });
};
