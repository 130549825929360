'use client';

import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { makeAxiosClient } from '@/legacy/common/axiosApiClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// must be a client component so it can access `globalThis`
export default function LegacyPageWrapper({
  children,
  legacyBaseURL,
  clerkEnabled,
}: PropsWithChildren<{ legacyBaseURL: string; clerkEnabled: boolean }>): JSX.Element {
  // do not try to move this to a hook, it will cause a too-many-hooks error when redirecting pages
  if (!globalThis.axiosInitialized) {
    makeAxiosClient(legacyBaseURL, clerkEnabled);
  }

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
