'use client';

import { PropsWithChildren, createContext, use, useEffect, useState } from 'react';
import Intercom, { update } from '@intercom/messenger-js-sdk';
import getUserMetadata from '@/lib/getUserMetadata';

type Props = {
  appId: string;
  isLoggedIn: boolean;
};

interface IntercomContextProps {
  hasInitialized: boolean;
  setIntercomMessengerEnabled: (enabled: boolean) => void;
}

const IntercomContext = createContext<IntercomContextProps>({
  hasInitialized: false,
  setIntercomMessengerEnabled: () => {},
});

export const useIntercomContext = () => {
  return use(IntercomContext);
};

const API_BASE = 'https://api-iam.intercom.io';

export const IntercomProvider = ({
  appId,
  children,
  isLoggedIn,
}: PropsWithChildren<Props>): JSX.Element => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [hasSetUserData, setHasSetUserData] = useState(false);
  const [isIntercomMessengerEnabled, setIntercomMessengerEnabled] = useState(false);

  // Boot Intercom
  useEffect(() => {
    Intercom({
      api_base: API_BASE,
      app_id: appId,
    });

    setHasInitialized(true);
  }, [appId]);

  // Once Intercom is initialized, set user data
  // If the user is not logged in, we don't set user data
  // If the user is logged in, we fetch the user data and set it
  useEffect(() => {
    if (!hasInitialized) {
      return;
    }

    if (!isLoggedIn) {
      setHasSetUserData(false);
    }

    // This is done asynchronously so we don't block the Intercom initialization.
    getUserMetadata().then((userSummary) => {
      if (!!userSummary) {
        update({
          email: userSummary.email,
          name: `${userSummary.firstName} ${userSummary.lastName}`,
          user_id: userSummary.id.toString(),
          hide_default_launcher: true,
        });
        setHasSetUserData(true);
      }
    });
  }, [hasInitialized, isLoggedIn]);

  // Once Intercom is initialized and user data is set, update the Intercom settings
  // based on the Intercom Messenger enabled state
  useEffect(() => {
    if (!hasInitialized || !hasSetUserData) {
      return;
    }

    update({
      hide_default_launcher: !isIntercomMessengerEnabled,
    });
  }, [hasInitialized, hasSetUserData, isIntercomMessengerEnabled]);

  return (
    <IntercomContext.Provider value={{ hasInitialized, setIntercomMessengerEnabled }}>
      {hasInitialized && !hasSetUserData && (
        <style>{
          // hide intercome messenger launcher via CSS to prevent "flash of unstyled content", except with the messenger icon.
          // `hide_default_launcher` disables products tours, which we need, and hiding the messenger launcher via Intercom GUI only minimizes the chatbox GUI, but still displays the messenger icon, which is not desirable.
          `.intercom-messenger-frame, .intercom-launcher-frame, .intercom-launcher-discovery-frame,
            .intercom-lightweight-app { display: none !important; }`
        }</style>
      )}
      {children}
    </IntercomContext.Provider>
  );
};
