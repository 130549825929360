import React from 'react';
import { Cross as CrossIcon } from '@ghosts-inc/design-system/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  PaperProps,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

// TODO: those styles need to be moved to the design system
// See ZRO-400 on Linear
export const DialogActionsSxProps: DialogActionsProps['sx'] = {
  justifyContent: 'flex-end',
  flexDirection: { mobile: 'column', smallTablet: 'row' },
  mt: { mobile: 3, smallTablet: 5 },
  rowGap: 2,
  columnGap: 1,
  '&.MuiDialogActions-root button': {
    ml: 0,
  },
};

export const DialogTitleSxProps: DialogTitleProps['sx'] = {
  flex: 1, // when the title is too long, it must be displayed on several lines instead of being cut.
};

export const DialogPaperSxProps: PaperProps['sx'] = {
  maxWidth: { mobile: 'none', smallTablet: '536px' },
  '&.MuiDialog-paper': {
    borderRadius: { mobile: 0, smallTablet: 3 },
    padding: { mobile: 3, smallTablet: 5 },
  },
};

type ConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  confirmText: string;
  body: string | React.ReactNode;
  closeText?: string;
};

const ConfirmModal = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  title,
  confirmText,
  body: propsBody,
  closeText,
}: ConfirmModalProps) => {
  const body =
    typeof propsBody === 'string' ? (
      <Typography variant="bodyLargeLight">{propsBody}</Typography>
    ) : (
      propsBody
    );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('mobile'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      PaperProps={{
        sx: DialogPaperSxProps,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <DialogTitle sx={DialogTitleSxProps}>{title}</DialogTitle>
        <IconButton aria-label="close" onClick={onCancel} size="small">
          <CrossIcon />
        </IconButton>
      </Stack>

      <Stack height="100%" justifyContent="space-between" marginTop={2.5}>
        <Stack>{body}</Stack>
        <DialogActions sx={DialogActionsSxProps}>
          <Button onClick={onClose} variant="outlined" fullWidth={isMobile}>
            {closeText}
          </Button>
          <Button variant="contained" onClick={onConfirm} fullWidth={isMobile}>
            {confirmText}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmModal;
