import axios, { AxiosError } from 'axios';
import { getAccessToken } from './axiosAuth';

export const isAxiosNetworkError = (error: Error) => {
  return error.message === 'Network Error';
};

export const makeAxiosClient = (baseURL: string, clerkEnabled: boolean) => {
  const axiosApiClient = axios.create({ baseURL });

  axiosApiClient.interceptors.request.use(async (config) => {
    if (clerkEnabled) {
      const token = await getAccessToken();

      return {
        ...config,
        headers: {
          ...config.headers,
          'Ghost-App-Type': 'marketplace',
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return {
      ...config,
      withCredentials: true,
      headers: {
        'Ghost-App-Type': 'marketplace',
        ...config.headers,
      },
    };
  });

  axiosApiClient.interceptors.response.use(undefined, async (error) => {
    if ((error as AxiosError).isAxiosError) {
      const originalRequest = error.config;

      if (!error.response) {
        throw new Error(error.message);
      }

      if (error.response.status === 401) {
        if (originalRequest.url === '/users/accounts') return;

        if (originalRequest.url === '/auth/sign-in') return;

        window.location.href = '/auth/sign-in';
      }

      if (error.response.data) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
    throw new Error(error);
  });

  // put it in the global state for use by legacy code
  globalThis.axiosApiClient = axiosApiClient;
  globalThis.axiosInitialized = true;

  return axiosApiClient;
};
