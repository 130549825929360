'use client';

import React, { createContext, useContext, useState } from 'react';
import ConfirmModal from './ConfirmModal';

/**
 * Modal display text + actions
 */
type ShowConfirmationProps = {
  title: string;
  body: string | React.ReactNode;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
  closeText?: string;
};

/**
 * Initialize with empty value.
 */
const ConfirmDialogContext = createContext<{
  showConfirmation: (args: ShowConfirmationProps) => void;
}>({
  showConfirmation: () => {},
});

/**
 * Returns a function that can be called to show the global confirmation modal, with updated values and callbacks
 * specified by the caller.
 */
export const useConfirm = ({
  title,
  body: bodyText,
  confirmText,
  onConfirm,
  onCancel,
  closeText = 'Close',
}: ShowConfirmationProps) => {
  const { showConfirmation } = useContext(ConfirmDialogContext);
  // Show confirmation modal with values specified by the caller
  return () =>
    showConfirmation({
      title,
      body: bodyText,
      confirmText,
      onConfirm,
      onCancel,
      closeText,
    });
};

/**
 * Used at the top level of the app to provide the global confirmation modal.
 */
export const ConfirmDialogProvider = ({ children }: { children: React.ReactNode }) => {
  // State has to be an object, or you get race conditions between various updates happening. Things run out of order.
  const [state, setState] = useState<ShowConfirmationProps & { confirmModalOpen: boolean }>({
    confirmModalOpen: false,
    title: '',
    body: '',
    confirmText: '',
    onConfirm: () => {},
    closeText: 'Close',
  });

  // This is the function that actually updates state when used by the various confirmers.
  const setConfirmValues = ({
    title: callbackTitle,
    body: callbackBody,
    confirmText: callbackConfirmText,
    onConfirm: callbackOnConfirm,
    onCancel: callbackOnCancel,
    closeText: callbackCloseText,
  }: ShowConfirmationProps) => {
    setState({
      confirmModalOpen: true,
      title: callbackTitle,
      body: callbackBody,
      confirmText: callbackConfirmText,
      onConfirm: callbackOnConfirm,
      onCancel: callbackOnCancel,
      closeText: callbackCloseText,
    });
  };

  const closeModal = () => {
    setState((prevState) => {
      return {
        ...prevState,
        confirmModalOpen: false,
      };
    });
  };

  return (
    <ConfirmDialogContext.Provider value={{ showConfirmation: setConfirmValues }}>
      {children}
      <ConfirmModal
        open={state.confirmModalOpen}
        onClose={closeModal}
        onCancel={() => {
          // If no onCancel callback is specified, we just close the modal
          state.onCancel?.();
          closeModal();
        }}
        onConfirm={() => {
          state.onConfirm();
          closeModal();
        }}
        title={state.title}
        body={state.body}
        confirmText={state.confirmText}
        closeText={state.closeText}
      />
    </ConfirmDialogContext.Provider>
  );
};
